// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import properties from 'src/store/apps/property';
import singleProperty from 'src/store/apps/single-property';
import searchProperty from './apps/search-property';
import blogs from 'src/store/apps/blog';
import insurances from 'src/store/apps/insurance';
import dropdown from 'src/store/apps/dropdown';
import pages from 'src/store/apps/pages';

export const store = configureStore({
  reducer: {
    properties,
    singleProperty,
    searchProperty,
    blogs,
    pages,
    insurances,
    dropdown,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
